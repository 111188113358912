<template>
    <div>
      <v-layout wrap justify-center my-6>
        <v-flex pt-5 xs11 sm11 md11 lg11 xl12>
        
  
          <!-- table Section -->
  
          <v-layout wrap justify-center>
            <v-flex xs12>

                <v-card>
                    <v-card-title>
                      <span class="churchdes">Church Update Log</span>
                    </v-card-title>
                    <v-card-subtitle  class="text-des">
                      <v-chip :color="statusColor" text-color="black">{{ logArray.status }}</v-chip>
                    </v-card-subtitle>
                    <v-card-text>
                      <v-list class="live-des">
                        <v-list-item  >
                          <v-list-item-content>
                            <v-list-item-title>Church ID:</v-list-item-title>
                            <v-list-item-subtitle>{{ logArray.churchId }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Created At:</v-list-item-title>
                            <v-list-item-subtitle>{{ formatDate1(logArray.createdAt) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Updated At:</v-list-item-title>
                            <v-list-item-subtitle>{{ formatDate1(logArray.updatedAt) }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Current Data:</v-list-item-title>
                            <v-list-item-subtitle>
                             
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Updated Data:</v-list-item-title>
                            <v-list-item-subtitle>
                         
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Updated By:</v-list-item-title>
                            <v-list-item-subtitle></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                  </v-card>


              <!-- <v-data-table
                :headers="headers"
                :items="managerslist"
                hide-default-footer
                class="elevation-1 text--black"
                id="virtual-scroll-table"
                @click:row="redirectToViewPage" style="cursor: pointer;"
              >
             
                <template v-slot:[`item._id`]="{ item }">
                  <v-icon
                    small
                    color="primary"
                    class="ml-1"
                    @click="editSlider(item)"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon
                    small
                    color="red"
                    class="ml-1"
                    @click.stop="openDeleteDialog(item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table> -->
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
  

    </div>
  </template>
  
  <script>
  import axios from "axios";
  //import { search } from 'core-js/fn/symbol';
  export default {
    data() {
      return {
        // search: "",
  
        // search1: "",
  
        // dialog: false,
        // userArray: [],
        // churchIds: [],
        // user: [],
        // role: "user",
        logArray: {},
        // churchArray: [],
        // church: "",
        // headers: [
        //   { text: "Name", value: "name", width: "200px" },
        //   { text: "Email", value: "email", width: "200px" },
        //   { text: "Phone", value: "phoneNumber", width: "50px" },
        //   // { text: "Role", value: "role", width: "50px" },
  
        //   // { text: "Actions", value: "_id", width: "5px", }
        // ],
      };
    },
    mounted() {
      this.getData();
    //   this.getParishManagers();
    //   this.getChurch();
    },
    // watch: {
    //   search(val) {
    //     this.loading = true;
    //     setTimeout(() => {
    //       this.getData(val);
    //       this.loading = false;
    //     }, 500);
    //   },
    //   search1(val) {
    //     this.loading = true;
    //     setTimeout(() => {
    //       this.getChurch(val);
    //       this.loading = false;
    //     }, 500);
    //   },
    //   // church() {
    //   //   this.getChurch();
    //   //      },
    // },
    methods: {
        formatDate1(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('en-GB').replace(/\//g, '-');
    },
    //   redirectToViewPage(item) {
    //       this.$router.push('/singleViewManagers?id=' + item._id);
    //     },
      getData() {
        this.appLoading = true;
        axios({
          url: "/get/church/update/detailed/log",
          method: "GET",
          params: {
            // role: this.role, // Pass the role parameter here
            // keyword: this.search,
            logId:this.$route.query.id,
          },
          headers: {
            token: localStorage.getItem("token"),
          },
        })
          .then((response) => {
            this.appLoading = false;
            this.logArray = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
  
    //   getChurch() {
    //     this.appLoading = true;
    //     //   const currentTime = new Date().toISOString(); // Get current time in ISO format
    //     axios({
    //       url: "/get/church/list",
  
    //       method: "GET",
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //       params: {
    //         keyword: this.search1,
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         this.churchArray = response.data.data;
    //         // this.pages = response.data.pages;
    //         // this.totalData = response.data.count;
    //         //this.pages = Math.ceil(response.data.totalLength / this.limit);
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
  
    //   getParishManagers() {
    //     this.appLoading = true;
    //     axios({
    //       url: "/parish/manager/list",
    //       method: "GET",
    //       // params: {
    //       //   role: this.role, // Pass the role parameter here
    //       // },
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         this.managerslist = response.data.data;
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
  
      // itemadd() {
      //     var data = {};
      //     data["name"] = this.name;
  
      //     axios({
      //         url: "/add/new/country",
      //         method: "POST",
      //         data: data,
      //         headers: {
      //             token: localStorage.getItem("token"),
      //         },
      //     })
      //         .then((response) => {
      //             this.appLoading = false;
      //             if (response.data.status) {
      //                 this.msg = "Added Sucessfully";
      //                 this.showsnackbar = true;
      //                 this.dialog = false;
      //                 this.name = null;
  
      //                 this.getData();
      //             } else {
      //                 this.msg = response.data.msg;
      //                 this.showsnackbar = true;
      //             }
      //         })
      //         .catch((err) => {
      //             this.appLoading = false;
      //             this.ServerError = true;
      //             console.log(err);
      //         });
      // },
      // getData() {
      //     this.appLoading = true;
      //     axios({
      //         url: "/get/country/list",
  
      //         method: "GET",
      //         headers: {
      //             token: localStorage.getItem("token"),
      //         },
      //         params: {
      //             //  count: this.count,
      //             // page: this.currentPage,
  
      //         },
      //     })
      //         .then((response) => {
      //             this.appLoading = false;
      //             this.countrylist = response.data.data;
      //             // this.pages = response.data.pages;
      //             // this.totalData = response.data.count;
      //         })
      //         .catch((err) => {
      //             this.appLoading = false;
      //             this.ServerError = true;
      //             console.log(err);
      //         });
      // },
  
      // editSlider(item) {
      //     this.editingitem = item;
      //     this.editdialog = true;
  
    //   // },
    //   assignUser() {
    //     this.appLoading = true;
  
    //     const selectedUserId = this.user; // Get the selected user's _id from v-autocomplete
    //     const selectedChurchIds = this.churchIds; // Get the selected churches' _ids from v-autocomplete
  
    //     // Prepare the payload to be sent to the backend
    //     const payload = {
    //       id: selectedUserId,
    //       churchIds: selectedChurchIds,
    //     };
  
    //     axios({
    //       url: "/assign/parish/manager",
    //       method: "POST",
    //       data: payload,
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.appLoading = false;
    //         if (response.data.status) {
    //           this.editdialog = false;
    //           this.getParishManagers();
    //           this.user = null; // Reset user selection
    //           this.churchIds = []; // Reset church selection
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showsnackbar = true;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
  
    //   openDeleteDialog(item) {
    //     this.itemToDelete = item;
    //     this.deletedialog = true;
    //   },
    //   confirmDelete() {
    //     if (this.itemToDelete) {
    //       this.deleteItem(this.itemToDelete);
    //     }
    //     this.deletedialog = false;
    //   },
    //   deleteItem(r) {
    //     var data = {};
    //     data["id"] = r._id;
    //     axios({
    //       url: "/delete/country",
    //       method: "POST",
    //       data: data,
    //       headers: {
    //         token: localStorage.getItem("token"),
    //       },
    //     })
    //       .then((response) => {
    //         this.delete = false;
    //         this.appLoading = false;
    //         if (response.data.status) {
    //           this.msg = "Delete Sucessfully";
    //           this.showsnackbar = true;
    //           this.getData();
    //         } else {
    //           this.msg = response.data.msg;
    //           this.showsnackbar = true;
    //         }
    //       })
    //       .catch((err) => {
    //         this.appLoading = false;
    //         this.ServerError = true;
    //         console.log(err);
    //       });
    //   },
    },
  };
  </script>
  
  <style scoped>
  .dialog-card {
    font-family: interbold;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .dialog-icon {
    animation: pulse 1s infinite alternate;
  }
  
  .dialog-button {
    min-width: 120px;
  }
  
  @keyframes pulse {
    from {
      transform: scale(1);
      opacity: 0.7;
    }
  
    to {
      transform: scale(1.1);
      opacity: 1;
    }
  }
  .table-responsive {
    overflow-x: auto;
  }
  
  .table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
    background-color: white;
    table-layout: fixed; /* Fixes column widths to prevent stretching */
  }
  
  .table th,
  .table td {
    padding: 12px;
    text-align: left;
  }
  
  .table th {
    background-color: white;
    font-family: interbold;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
  }
  
  .table td {
    font-family: interregular;
    font-size: 15px;
  }
  
  .table-row:hover {
    background-color: #eff2f6;
  }
  
  .actions-column {
    text-align: center; /* Center-aligns the action buttons */
  }
  </style>